import { sql } from "drizzle-orm";
import { jsonb, timestamp, varchar } from "drizzle-orm/pg-core";
import { z } from "zod";
import type { TenantId } from "../../agid";
import { agid } from "../custom-types";

export const baseNoId = {
  tenantId: agid("tenant_id").$type<TenantId>().notNull(),

  createdAt: timestamp("created_at", {
    precision: 6,
    withTimezone: true,
  })
    .default(sql`now()`)
    .notNull(),

  updatedAt: timestamp("updated_at", {
    precision: 6,
    withTimezone: true,
  }),
};

// All tables must have these columns.
export function createBaseWithId<T extends string>() {
  return {
    ...baseNoId,

    id: agid("id")
      .$type<T>()
      .default(sql`gen_random_uuid()`)
      .primaryKey()
      .notNull(),
  };
}

// For entities where we need to track who created and updated the record.
export const ownership = {
  createdBy: jsonb("created_by")
    .$type<unknown>()
    .notNull()
    .default(sql`'{"_type": "seed"}'::jsonb`), // TODO: remove after migration
  updatedBy: jsonb("updated_by").$type<unknown>(),
};

/** Track soft deletes for records which can be deleted. */
export const deletable = {
  deletedAt: timestamp("deleted_at", {
    precision: 6,
    withTimezone: true,
  }),
  deletedBy: jsonb("deleted_by").$type<unknown>(),
};

// Each config object has a lifecycle. We use a unique constraint
// on `tenant_id, ident, status` to ensure:
// - There is only ever one DRAFT version.
// - There is only ever one ACTIVE version.
// - There can be many past versions, represented by `status = `.
const lifecycle = ["DRAFT", "ACTIVE", "ARCHIVED"] as const;
export const LifecycleStatus = z.enum(lifecycle); //
export type LifecycleStatus = z.infer<typeof LifecycleStatus>;

export const config = {
  // Versioning columns, we create the unique keys on these two.
  ident: varchar("ident")
    .notNull()
    .default(
      // TODO: remove after migration
      sql`substr(sha256(gen_random_uuid()::text::bytea)::text, 10, 20)`,
    ),
  status: varchar("status").default("DRAFT").notNull().$type<LifecycleStatus>(),

  // Standard Config data columns
  name: varchar("name").notNull(),
  description: varchar("description").notNull(),
  config: jsonb("config")
    .notNull()
    .default(sql`'{}'::jsonb`), // TODO: remove after migration

  // Tags
  tags: varchar("tags")
    .array()
    .notNull()
    .default(sql`ARRAY[]::VARCHAR[]`),
};
