import type { AgentId, GuideId, TeamConfigId, ToolId } from "../../agid";
import { config, createBaseWithId, deletable, ownership } from "./base";
import { agConfig } from "./schema";
import { unique } from "drizzle-orm/pg-core";

export const Guide = agConfig.table(
  "guide",
  {
    ...createBaseWithId<GuideId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_guide_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Agent = agConfig.table(
  "agent",
  {
    ...createBaseWithId<AgentId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_agent_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

/**
 * Team Config
 *
 * @remarks was earlier Agent Workflow.
 *
 */
export const TeamConfig = agConfig.table(
  "agent_workflow",
  {
    ...createBaseWithId<TeamConfigId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_agent_workflow_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);

export const Tool = agConfig.table(
  "tool",
  {
    ...createBaseWithId<ToolId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => {
    return {
      uniqueKey: unique("uq_tool_tenant_id_ident_status").on(
        table.tenantId,
        table.ident,
        table.status,
      ),
    };
  },
);
