import { sql } from "drizzle-orm";
import {
  boolean,
  index,
  jsonb,
  pgEnum,
  uniqueIndex,
  varchar,
} from "drizzle-orm/pg-core";
import type { UserId, UserTenantMappingId } from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";

export const UserStatus = [
  "CREATING",
  "ACTIVE",
  "INACTIVE",
  "SUSPENDED",
] as const;
export type UserStatus = (typeof UserStatus)[number];
export const pgUserStatus = pgEnum("user_status", UserStatus);

export const UserType = ["SUPERUSER", "USER"] as const;
export type UserType = (typeof UserType)[number];
export const pgUserType = pgEnum("user_type", UserType);

export const UserRole = ["ADMIN", "STANDARD"] as const;
export type UserRole = (typeof UserRole)[number];
export const pgUserRole = pgEnum("user_role", UserRole);

export const User = agConfig.table(
  "user",
  {
    ...createBaseWithId<UserId>(),
    ...ownership,

    userType: pgUserType("user_type").default("USER").notNull(),
    userStatus: pgUserStatus("user_status").default("CREATING").notNull(),
    firstName: varchar("first_name"),
    lastName: varchar("last_name"),
    email: varchar("email").unique().notNull(),
    shortId: varchar("short_id").unique().notNull(),
    config: jsonb("config").notNull(),
  },
  (table) => {
    return {
      tenantId: index("ix_user_tenant_id").on(table.tenantId),
    };
  },
);

export const UserTenantMapping = agConfig.table(
  "user_tenant_mapping",
  {
    ...createBaseWithId<UserTenantMappingId>(),
    ...ownership,
    userId: agid("user_id")
      .$type<UserId>()
      .references(() => User.id)
      .notNull(),
    defaultTenant: boolean("default_tenant").default(false).notNull(),
    userRole: pgUserRole("user_role").notNull(),
    config: jsonb("config").notNull(),
  },
  (table) => {
    return {
      tenantId: index("ix_user_tenant_mapping_tenant_id").on(table.tenantId),
      onlyOneMembership: uniqueIndex().on(table.userId, table.tenantId),
      singleDefaultTenant: uniqueIndex("ix_user_single_default_tenant")
        .on(table.userId)
        .where(sql`${table.defaultTenant} = true`),
    };
  },
);
