// ByteA is not supported by drizzle-orm
import { customType } from "drizzle-orm/pg-core";

export const ByteA = customType<{ data: Buffer }>({
  dataType() {
    return "bytea";
  },
});

// Drizzle custom column
export const agid = <TData extends string>(name: string) =>
  customType<{ data: TData }>({
    dataType() {
      return "uuid";
    },
    toDriver(value: TData) {
      return value.toString();
    },
  })(name);
