import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";
import { SuperUserRoute } from "src/utils/auth";

const AdminPage = lazy(() => import("@/pages/admin/index"));

const TakeoutImportPage = lazy(() => import("@/pages/admin/takeout/import"));
const TakeoutExportPage = lazy(() => import("@/pages/admin/takeout/export"));
const TakeoutOutlinePage = lazy(() => import("@/pages/admin/takeout/outline"));

const WorkflowPage = lazy(() => import("@/pages/admin/workflows"));

export const adminRoutes: RouteObject[] = [
  {
    path: paths.admin.overview,
    element: <SuperUserRoute element={<AdminPage />} />,
  },
  {
    path: paths.admin.takeout.import,
    element: <SuperUserRoute element={<TakeoutImportPage />} />,
  },
  {
    path: paths.admin.takeout.export,
    element: <SuperUserRoute element={<TakeoutExportPage />} />,
  },
  {
    path: paths.admin.takeout.outline,
    element: <SuperUserRoute element={<TakeoutOutlinePage />} />,
  },
  {
    path: paths.admin.workflows,
    element: <SuperUserRoute element={<WorkflowPage />} />,
  },
];
