import { sql } from "drizzle-orm";
import {
  index,
  integer,
  jsonb,
  uniqueIndex,
  varchar,
} from "drizzle-orm/pg-core";
import type {
  BatchId,
  ItemId,
  ItemWebhookId,
  LabItemId,
  ResultId,
  SlackWebhookId,
  StepId,
  WorksheetId,
  FileImportId,
} from "../../agid";
import { agid } from "../custom-types";
import { config, createBaseWithId, deletable, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";

/** A Worksheet contains data, and automation which acts on that data. */
export const Worksheet = agConfig.table(
  "worksheet",
  {
    ...createBaseWithId<WorksheetId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => ({
    uniqueKey: uniqueIndex()
      .on(table.tenantId, table.ident, table.status)
      .where(sql`${table.status} != 'ARCHIVED'`),
  }),
);

/** Worksheets contain an ordered sequence of Steps which defines the automation flow. */
export const Step = agConfig.table(
  "step",
  {
    ...createBaseWithId<StepId>(),
    ...ownership,
    ...deletable,

    /** Give each step a UI name. */
    name: varchar("name").notNull(),

    /** Optional step description. */
    description: varchar("description"),

    /** The ID of the worksheet this step belongs to. */
    worksheetId: agid("worksheet_id")
      .$type<WorksheetId>()
      .notNull()
      .references(() => Worksheet.id),

    /** The ident of the step (maintains identity across worksheet versions) */
    ident: varchar("ident").notNull(),

    /** The step's position in the worksheet. Sort by this to get steps in order. */
    position: integer("position").notNull(),

    /** The step's action configuration. */
    action: jsonb("config").notNull(),
  },
  (table) => ({
    identInWorksheet: uniqueIndex().on(
      table.tenantId,
      table.worksheetId,
      table.ident,
    ),
  }),
);

/** An Item Webhook maps a randomly-generated token to a particular tenant and worksheet. */
export const ItemWebhook = agConfig.table(
  "item_webhook",
  {
    ...createBaseWithId<ItemWebhookId>(),
    ...ownership,
    ...config,
    ...deletable,

    /** Webhook token. */
    token: varchar("token").notNull(),

    /** Ident of the worksheet to load data into. */
    worksheetIdent: varchar("worksheet_ident").notNull(),
  },
  (table) => ({
    token: uniqueIndex().on(table.token),
    uniqueKey: uniqueIndex()
      .on(table.tenantId, table.ident, table.status)
      .where(sql`${table.status} != 'ARCHIVED'`),
  }),
);

/** Items are individual records uploaded to a Worksheet by a user or integration (e.g. Webhooks). */
export const Item = agRuntime.table(
  "item",
  {
    ...createBaseWithId<ItemId>(),
    ...ownership,
    ...deletable,

    /* Worksheet to which this item belongs. */
    worksheetId: agid("worksheet_id").notNull(),

    /** Item data. */
    data: jsonb("data").notNull(),

    /* Item status */
    status: varchar("status").notNull(),
  },
  (table) => ({
    byWorksheetStatus: index().on(
      table.tenantId,
      table.worksheetId,
      table.status,
    ),
  }),
);

export const LabItem = agRuntime.table("lab_item", {
  ...createBaseWithId<LabItemId>(),
  ...ownership,
  ...deletable,

  /** The type of the entity this lab item is for (i.e. agent, task, tool). */
  memberType: varchar("member_type").notNull(),

  /** The UUID of the entity that this lab item is for. */
  memberOf: agid("member_of").notNull(),

  /** The UUID of the Item that this lab item is derived from if applicable. */
  itemId: varchar("item_id").$type<ItemId>(),

  /** The input for this lab item. */
  input: jsonb("input").notNull(),

  /** The output of this lab item. */
  output: jsonb("output"),
});

/** Results are the output of a worksheet's sequence of Steps for a particular item.*/
export const Result = agRuntime.table(
  "result",
  {
    ...createBaseWithId<ResultId>(),
    ...ownership,
    ...deletable,

    /* Worksheet to which this result belongs. */
    worksheetId: agid("worksheet_id").notNull(),

    /** The status of the result (queued for a batch, executing, done, error, etc.) */
    status: varchar("status").notNull(),

    /** The Item that this result is for. */
    itemId: agid("item_id").$type<ItemId>().notNull(),

    /** The step that produced this result. */
    stepId: agid("step_id")
      .$type<StepId>()
      .notNull()
      .references(() => Step.id),

    /** The batch that this result is part of, if any. */
    batchId: agid("batch_id")
      .$type<BatchId>()
      .references(() => Batch.id),

    /** The output of the step, if it has completed successfully. */
    result: jsonb("result"),

    /** Any error produced by the step, if it has failed. */
    error: jsonb("error"),

    /** Any message produced by the step, if it has completed, could be error or success, this is user visible. */
    message: varchar("message"),

    /** Internal, non-UI metadata; for instance: the Marketo upload batch ID, to
     * avoid shuttling hundreds of IDs through Temporal. */
    metadata: jsonb("metadata"),
  },
  (table) => ({
    byItemAndStep: uniqueIndex()
      .on(table.tenantId, table.itemId, table.stepId)
      .where(sql`${table.deletedAt} is null`),

    // We need this to efficiently (-ish) count the number of records in a batch.
    byBatch: index().on(table.tenantId, table.batchId),
  }),
);

/**
 * For batch steps, Results reference a Batch, which designates a group of
 * results which is processed together (e.g. exported to Marketo as part of the same CSV).
 */
export const Batch = agRuntime.table(
  "batch",
  {
    ...createBaseWithId<BatchId>(),
    ...ownership,
    ...deletable,

    /* Worksheet to which this batch belongs. */
    worksheetId: agid("worksheet_id").notNull(),

    /** The status of the batch (queued, executing, done, error, etc.) */
    status: varchar("status").notNull(),

    /** The step this batch is for. */
    stepId: agid("step_id")
      .$type<StepId>()
      .notNull()
      .references(() => Step.id),

    /** Batch-level result data. */
    result: jsonb("result"),

    /** Batch-level error data. */
    error: jsonb("error"),
  },
  (table) => ({
    byWorksheet: index().on(table.tenantId, table.worksheetId),
    byStep: index("ix_batch_step").on(table.tenantId, table.stepId),
    onlyOneOpenPerStep: uniqueIndex("ix_batch_only_one_per_step")
      .on(table.tenantId, table.stepId)
      .where(sql`${table.status} = 'OPEN'`),
  }),
);

/** Slack Webhook **/
export const SlackWebhook = agConfig.table(
  "slack_webhook",
  {
    ...createBaseWithId<SlackWebhookId>(),
    ...ownership,
    ...config,
    ...deletable,
  },
  (table) => ({
    uniqueKey: uniqueIndex()
      .on(table.tenantId, table.ident, table.status)
      .where(sql`${table.status} != 'ARCHIVED'`),
  }),
);

export const FileImport = agRuntime.table(
  "file_import",
  {
    ...createBaseWithId<FileImportId>(),
    ...ownership,
    ...deletable,

    /* Worksheet to which this batch belongs. */
    worksheetId: agid("worksheet_id").notNull(),

    /** The status of the batch (queued, executing, done, error, etc.) */
    status: varchar("status").notNull(),

    /** File-level  metadata, e.g. the URL's for the files for data. */
    metadata: jsonb("metadata"),

    /** File-level conversion message or error data. */
    message: jsonb("message"),
  },
  (table) => ({
    byWorksheet: index().on(table.tenantId, table.worksheetId),
  }),
);
