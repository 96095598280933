import {
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { Issuer } from "src/utils/auth";

const loginPaths: Record<Issuer, string> = {
  [Issuer.JWT]: paths.auth.login,
};

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const { isInitialized, isAuthenticated } = useAuth();
  const [checked, setChecked] = useState<boolean>(false);

  const check = useCallback(() => {
    if (isInitialized && !isAuthenticated) {
      if (window.location.pathname !== "/login") {
        localStorage.setItem(
          "RETURN_TO",
          `${window.location.pathname}${window.location.search}`,
        );
      }
      const href = loginPaths[Issuer.JWT];
      router.replace(href);
    } else {
      setChecked(true);
    }
  }, [isInitialized, isAuthenticated, router]);

  // Only check on mount, this allows us to redirect the user manually when auth state changes
  useEffect(() => {
    check();
  }, [check]);

  if (!checked) {
    return null;
  }

  // If got here, it means that the redirect did not occur, and that tells us that the user is
  // authenticated / authorized.

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
