import GenericError from "./4xx";
import Page401 from "./401";
import Page404 from "./404";
import Page500 from "./500";
import { type TRPC_ERROR_CODE_KEY } from "@trpc/server/rpc";

// TODO: Make a consistent error handling strategy
// such that a Not Found in TRPC percolates up to the
// client and is percieved as a 404.
// ALL-2425
export class ResponseError extends Error {
  code: TRPC_ERROR_CODE_KEY;
  constructor(message: string, code: TRPC_ERROR_CODE_KEY) {
    super(message);
    this.code = code;
    Object.setPrototypeOf(this, ResponseError.prototype);
  }
}

export const ErrorFallback = ({ error }: { error: ResponseError }) => {
  if (error.code === "UNAUTHORIZED") {
    return <Page401 />;
  }

  if (error.code === "NOT_FOUND") {
    return <Page404 />;
  }

  if (error.code === "INTERNAL_SERVER_ERROR") {
    return <Page500 />;
  }

  return <GenericError />;
};
