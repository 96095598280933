import { z } from "zod";

export const WorksheetColors = z.enum([
  "agYellow.300",
  "agOrange.300",
  "agBlue.500",
  "agPink.500",
  "agTeal.300",
  "agYellow.400",
  "agOrange.400",
  "agBlue.600",
  "agPink.600",
  "agTeal.400",
  "agYellow.500",
  "agOrange.500",
  "agBlue.700",
  "agPink.700",
  "agTeal.500",
  "agYellow.600",
  "agOrange.600",
  "agBlue.800",
  "agPink.800",
  "agTeal.600",
  "agYellow.700",
  "agOrange.700",
  "agBlue.900",
  "agPink.900",
  "agTeal.700",
]);
export type WorksheetColors = z.infer<typeof WorksheetColors>;

export const WorksheetIcons = z.enum([
  "autoAwesome",
  "campaign",
  "science",
  "signal",
  "source",
  "trendingFlat",
  "monitorHeart",
  "leaderboard",
  "connectWithoutContact",
  "refresh",
  "developerBoard",
  "userEngagement",
  "polyLine",
  "insights",
  "dimension",
]);
export type WorksheetIcons = z.infer<typeof WorksheetIcons>;
