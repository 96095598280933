import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

import { Layout as SettingsLayout } from "@/pages/settings/layout";
import { AdminRoute } from "src/utils/auth";

const AccountPage = lazy(() => import("src/pages/settings/account/account"));
const HandlersPage = lazy(() => import("src/pages/settings/handlers/handlers"));
const ChatThreadsListPage = lazy(
  () => import("src/pages/settings/chat-threads/threads-list"),
);
const TeamPage = lazy(() => import("src/pages/settings/team/team"));
const TenantDetailsPage = lazy(
  () => import("src/pages/settings/tenant/tenant"),
);

const IntegrationsPage = lazy(
  () => import("src/pages/settings/integrations/integrations-page"),
);
const IntegrationForm = lazy(
  () => import("src/pages/settings/integrations/components/integration-form"),
);
export const settingRoutes: RouteObject[] = [
  {
    path: paths.settings.index,
    element: (
      <SettingsLayout>
        <AccountPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.account,
    element: (
      <SettingsLayout>
        <AccountPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.team,
    element: (
      <SettingsLayout>
        <TeamPage />
      </SettingsLayout>
    ),
  },
  {
    path: paths.settings.integrations,
    element: (
      <AdminRoute
        element={
          <SettingsLayout>
            <IntegrationsPage />
          </SettingsLayout>
        }
      />
    ),
  },
  {
    path: paths.settings.handlers,
    element: (
      <AdminRoute
        element={
          <SettingsLayout>
            <HandlersPage />
          </SettingsLayout>
        }
      />
    ),
  },
  {
    path: paths.settings.chatThreads,
    element: <ChatThreadsListPage />,
  },
  {
    path: paths.settings.createIntegration,
    element: (
      <AdminRoute
        element={
          <SettingsLayout>
            <IntegrationForm state="create" />
          </SettingsLayout>
        }
      />
    ),
  },
  {
    path: paths.settings.configureIntegration,
    element: (
      <AdminRoute
        element={
          <SettingsLayout>
            <IntegrationForm state="update" />
          </SettingsLayout>
        }
      />
    ),
  },
  {
    path: paths.settings.tenant,
    element: (
      <AdminRoute
        element={
          <SettingsLayout>
            <TenantDetailsPage />
          </SettingsLayout>
        }
      />
    ),
  },
];
