import { timestamp, unique, uniqueIndex, varchar } from "drizzle-orm/pg-core";
import type { IntegrationId, TokenCacheId } from "../../agid";
import { agid } from "../custom-types";
import { config, createBaseWithId, deletable, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";
import { sql } from "drizzle-orm";

export const Integration = agConfig.table(
  "integration",
  {
    ...createBaseWithId<IntegrationId>(),
    ...ownership,
    ...config,
    ...deletable,

    integrationType: varchar("integration_type").notNull(),

    /** Integration credentials, encrypted with the credential KMS key.
     *
     * Base64-encoded (as returned from KMS API) encrypted blob. When decrypted,
     * it should be a UTF-8-encoded JSON blob, whose type is determined by the
     * `integration_type`.
     * */
    encryptedCredentials: varchar("encrypted_credentials").notNull(),
  },
  (table) => ({
    uniqueKey: uniqueIndex()
      .on(table.tenantId, table.ident, table.status)
      .where(sql`${table.status} != 'ARCHIVED'`),
  }),
);

export const TokenCache = agRuntime.table(
  "token_cache",
  {
    ...createBaseWithId<TokenCacheId>(),
    ...ownership,

    /** The IntegrationId for which this token was created. */
    integrationId: agid("integration_id").$type<IntegrationId>().notNull(),

    /** The expiry time for a token */
    expiresAt: timestamp("expires_at", {
      precision: 6,
      withTimezone: true,
    }),
    encryptedToken: varchar("encrypted_token").notNull(),
  },
  (table) => {
    return {
      uniqueKey: unique("uq_token_cache_integration_id").on(
        table.tenantId,
        table.integrationId,
      ),
    };
  },
);
