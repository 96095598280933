import { useAuth } from "@/hooks/use-auth";
import { ALLGOOD_TENANT_SHORT_ID } from "allgood-schema";
import { ResponseError } from "src/pages/error";
import type { RouterOutputs } from "src/utils/trpc";
export enum Issuer {
  JWT = "JWT",
}

export const checkIsAllGoodTenant = (user?: RouterOutputs["auth"]["me"]) => {
  return user?.tenant.shortId === ALLGOOD_TENANT_SHORT_ID;
};

export const checkIsAllgoodUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.email.endsWith("@allgoodhq.com");

export const checkIsSuperUser = (user?: RouterOutputs["auth"]["me"]) =>
  user?.userType === "SUPERUSER";

export const checkIsAdmin = (user?: RouterOutputs["auth"]["me"]) =>
  checkIsSuperUser(user) || user?.userRole === "ADMIN";

export const SuperUserRoute = ({ element }: { element: JSX.Element }) => {
  const { user } = useAuth();

  // If user is not a super user, throw an error
  // The reason we check for user first is because the auth context is wack and will initially be undefined
  // This will cause the page to throw an error before the user is even authenticated
  // but the context is still being created.
  // This is not good, but it works for now.
  if (user && !checkIsSuperUser(user)) {
    throw new ResponseError(
      "You are not authorized to access this page",
      "UNAUTHORIZED",
    );
  }

  return element;
};

export const AdminRoute = ({ element }: { element: JSX.Element }) => {
  const { user } = useAuth();

  // Same as above, but for admin
  if (user && !checkIsAdmin(user)) {
    throw new ResponseError(
      "You are not authorized to access this page",
      "UNAUTHORIZED",
    );
  }

  return element;
};
