import { index, jsonb, varchar } from "drizzle-orm/pg-core";
import type { ConfigHistoryId } from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";
export const ConfigHistory = agConfig.table(
  "config_history",
  {
    ...createBaseWithId<ConfigHistoryId>(),
    ...ownership,

    configOf: agid("config_of").notNull(),

    configType: varchar("config_type").notNull(),

    config: jsonb("config").$type<unknown>().notNull(),
  },
  (t) => ({
    currentByType: index("config_history_configType").on(
      t.tenantId,
      t.configType,
      t.createdAt, // Must come last to index the max(created_at)
    ),
  }),
);
