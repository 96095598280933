import { agBlue, agOrange, agTeal, agYellow } from "src/styles/theme/colors";

export const LabEntityColorMap = {
  primary: {
    agent: agYellow[500],
    team: agBlue[500],
    tool: agOrange[500],
    guide: agTeal[500],
  },
  secondary: {
    agent: agYellow[200],
    team: agBlue[200],
    tool: agOrange[200],
    guide: agTeal[200],
  },
  light: {
    agent: agYellow[50],
    team: agBlue[50],
    tool: agOrange[50],
    guide: agTeal[50],
  },
};
