import { RocketLaunch } from "@phosphor-icons/react/dist/csr/RocketLaunch";
import { Gear as GearIcon } from "@phosphor-icons/react/dist/csr/Gear";
import { House as HouseIcon } from "@phosphor-icons/react/dist/csr/House";
import { Building } from "@phosphor-icons/react/dist/csr/Building";
import { Users } from "@phosphor-icons/react/dist/csr/Users";
import {
  AddressBook,
  Binoculars,
  Brain,
  ChatCircleText,
  Database,
  Flask,
  type Icon,
  Path,
  ShieldCheckered,
  Sliders,
  Star,
  UsersThree,
  Wrench,
} from "@phosphor-icons/react";

export type NavIcons =
  | "home"
  | "campaign"
  | "companies"
  | "users"
  | "admin"
  | "settings";

export type FlowNavIcons = "items" | "flow" | "tracing" | "data" | "sliders";

export type LabNavIcons =
  | "lab"
  | "skills"
  | "teams"
  | "tools"
  | "guides"
  | "chat";

export const icons = {
  home: HouseIcon,
  campaign: RocketLaunch,
  companies: Building,
  users: Users,
  admin: ShieldCheckered,
  settings: GearIcon,
  // Flow Icons
  items: Star,
  flow: Path,
  tracing: Binoculars,
  data: Database,
  sliders: Sliders,
  // Lab Icons
  lab: Flask,
  skills: Brain,
  teams: UsersThree,
  tools: Wrench,
  guides: AddressBook,
  chat: ChatCircleText,
} as Record<NavIcons | FlowNavIcons | LabNavIcons, Icon>;
