import {
  index,
  jsonb,
  uniqueIndex,
  varchar,
  vector,
} from "drizzle-orm/pg-core";
import type {
  AgentId,
  DocumentId,
  ExecuteId,
  MessageId,
  ModelId,
  TenantId,
  ThreadId,
  ThreadRefId,
} from "../../agid";
import { agid } from "../custom-types";
import { createBaseWithId, deletable, ownership } from "./base";
import { agConfig, agRuntime } from "./schema";
import { sql } from "drizzle-orm";

export const Document = agRuntime.table(
  "document",
  {
    ...createBaseWithId<DocumentId>(),
    ...ownership,
    ...deletable,

    /** Content of the document */
    content: varchar("content").notNull(),

    /** Metadata for the document */
    metadata: jsonb("metadata")
      .notNull()
      .default(sql`'{}'::jsonb`),

    /** Agent associated with the document */
    agentId: agid("agent_id").$type<AgentId>(),

    /** Embedding for the document - OpenAI */
    embedding: vector("embedding", { dimensions: 1536 }).notNull(),
  },
  (t) => ({
    resultIdx: index("ix_document_agent").on(t.tenantId, t.agentId),
  }),
);

/**
 * Thread Ref is the context that we point to from the ThreadId
 */
export const ThreadRef = agRuntime.table(
  "thread_ref",
  {
    ...createBaseWithId<ThreadRefId>(),
    ...ownership,

    /** Source of the thread_ref */
    source: jsonb("source")
      .notNull()
      .default(sql`'{}'::jsonb`),

    /** ThreadId for the thread_ref */
    threadId: agid("thread_id").$type<ThreadId>().notNull(),

    /** TeamId captures team_config and agent_id, can include users etc later **/
    teamId: jsonb("team_id")
      .notNull()
      .default(sql`'{}'::jsonb`),

    /** Possible other metadata associated with the threadRef */
    metadata: jsonb("metadata"),
  },
  (t) => ({
    resultIdx: index("ix_thread_ref_source").on(t.tenantId, t.source),
    uniqueIndex: uniqueIndex().on(t.tenantId, t.source, t.threadId),
  }),
);

/**
 * Messages are our abstraction for LangGraph streamed events
 */
export const Message = agRuntime.table(
  "message",
  {
    ...createBaseWithId<MessageId>(),
    ...ownership,
    ...deletable,

    /** ThreadRefId for the message */
    threadRefId: agid("thread_ref_id").$type<ThreadRefId>().notNull(),

    /** ExecuteId - Linked to a single graph execution */
    executeId: agid("execute_id").$type<ExecuteId>().notNull(),

    /** Content of the message */
    content: varchar("content").notNull(),

    /** Metadata associated with the message */
    metadata: jsonb("metadata"),
  },
  (t) => ({
    resultIdx: index("ix_message_thread_ref_created_at").on(
      t.tenantId,
      t.threadRefId,
      t.createdAt,
    ),
    // TODO: Add more indexes?
  }),
);

/** Special table that is non-tenant specific - adding a null TenantID for  */
export const Model = agConfig.table(
  "model",
  {
    ...createBaseWithId<ModelId>(),
    ...ownership,
    tenantId: agid("tenant_id").$type<TenantId>(), // Will override the tenantId to be nullable

    /** Name of the model - User facing */
    name: varchar("name").notNull(),

    /** API Name of the model - will serve as ident since this is non-duplicated */
    ident: varchar("ident").notNull(),

    /** Description of the model */
    description: varchar("description"),
  },
  (t) => ({
    uniqueIndex: uniqueIndex().on(t.ident), // TODO: Add the TenantId to this
  }),
);
