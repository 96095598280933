import type { FC, ReactNode } from "react";

import { withAuthGuard } from "@/hocs/with-auth-guard";
import { HorizontalLayout } from "@/layouts/dashboard/layout/horizontal-layout/horizontal-layout";
import { paths } from "@/paths";
import type { NavItemConfig } from "@/types/nav";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard(({ children }) => {
  const items: NavItemConfig[] = [
    {
      key: "dashboard",
      title: "",
      items: [
        {
          key: "home",
          title: "HOME",
          href: paths.index,

          matcher: {
            type: "equals",
            href: paths.index,
          },
        },
        {
          key: "worksheet",
          title: "WORKSHEET",
          href: paths.worksheets.index,
          matcher: {
            type: "equals",
            href: paths.worksheets.index,
          },
        },
        {
          key: "lab",
          title: "LAB",
          href: paths.agents.index,
          matcher: {
            type: "startsWith",
            href: "/lab",
          },
        },
        {
          key: "settings",
          title: "",
          href: paths.settings.account,
          icon: "settings",
          matcher: {
            type: "startsWith",
            href: paths.settings.index,
          },
          tooltip: "Settings",
        },
        {
          key: "admin",
          title: "",
          href: paths.admin.overview,
          icon: "admin",
          matcher: {
            type: "startsWith",
            href: paths.admin.index,
          },
          allowedTypes: ["SUPERUSER"],
          tooltip: "Admin",
        },
      ],
    },
  ];
  return <HorizontalLayout items={items}>{children}</HorizontalLayout>;
});
