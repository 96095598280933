import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";
import { SuperUserRoute } from "src/utils/auth";

const SwitchTenantPage = lazy(() => import("src/pages/auth/jwt/switch"));

export const profileRoutes: RouteObject[] = [
  {
    path: paths.profile.switchTenant,
    element: <SuperUserRoute element={<SwitchTenantPage />} />,
  },
];
