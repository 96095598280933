import { sql } from "drizzle-orm";
import {
  index,
  jsonb,
  uniqueIndex,
  timestamp,
  varchar,
} from "drizzle-orm/pg-core";
import type { CacheId } from "../../agid";
import { ownership, createBaseWithId } from "./base";
import { agRuntime } from "./schema";

export const Cache = agRuntime.table(
  "cache",
  {
    ...createBaseWithId<CacheId>(),
    ...ownership,

    /**
     * Operation name. For instance, `scrapin_get_by_email` or similar.
     */
    op: varchar("op").notNull(),

    /**
     * Cache key.
     */
    key: jsonb("key").notNull(),

    /**
     * When the cache value is null, this indicates a dummy row, used to
     * implement locking through `select for update`.
     */
    value: jsonb("value"),

    /** Null for a cache entry which never expires. */
    expiresAt: timestamp("expires_at"),
  },
  (t) => ({
    uniqueByOpKey: uniqueIndex().on(t.tenantId, t.op, t.key),
    findByKey: index().using("gin", t.key),
    byExpiry: index().on(t.expiresAt),
  }),
);
