import type { FC } from "react";
import toast, { Toaster as HotToaster } from "react-hot-toast";
import { alpha } from "@mui/system/colorManipulator";
import { useTheme } from "@mui/material/styles";
import { agOrange, agPink, agTeal, agYellow } from "src/styles/theme/colors";
import { Box } from "@mui/system";
export const Toaster: FC = () => {
  const theme = useTheme();

  return (
    <HotToaster
      position="bottom-right"
      toastOptions={{
        style: {
          borderRadius: "24px",
          background: alpha(agPink[500], 0.95),
          color: theme.palette.common.white,
          boxShadow: theme.shadows[16],
        },
        success: {
          style: {
            background: alpha(agYellow[500], 0.95),
          },
          iconTheme: {
            primary: theme.palette.common.white,
            secondary: agYellow[500],
          },
        },
        error: {
          style: {
            background: alpha(agOrange[500], 0.95),
          },
          iconTheme: {
            secondary: agOrange[500],
            primary: theme.palette.common.white,
          },
        },
        loading: {
          style: {
            background: alpha(agPink[500], 0.95),
          },
          iconTheme: {
            primary: agTeal[300],
            secondary: theme.palette.common.white,
          },
        },
      }}
    />
  );
};

/**
 * Utility method to get a toast with a Mary logo in it.
 */
export function maryToast(
  message: string,
  {
    duration = 5000,
    iconUrl = "https://cdn.prod.website-files.com/6537ecf8dd6837236ee1b763/671928c38a76da7c1f729de7_maryv2-p-500.webp",
    toastId,
  }: {
    duration?: number;
    iconUrl?: string;
    toastId?: string;
  },
) {
  const size = "32px";

  const icon = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <img
        src={iconUrl}
        alt="icon"
        style={{
          width: size,
          height: size,
          borderRadius: "100px",
        }}
      />
    </Box>
  );

  if (toastId) {
    return toast.loading(message, {
      duration,
      icon,
      id: toastId,
    });
  }

  return toast.loading(message, {
    duration,
    icon,
  });
}
