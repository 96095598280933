import type { Components } from "@mui/material/styles";

import type { Theme } from "../types";
import { agPink } from "../colors";

export const MuiTooltip = {
  defaultProps: { placement: "top" },
  styleOverrides: {
    tooltip: { backdropFilter: "blur(6px)", background: agPink[600] },
  },
} satisfies Components<Theme>["MuiTooltip"];
