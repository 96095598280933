import { jsonb, pgEnum, varchar } from "drizzle-orm/pg-core";
import type { TenantId } from "../../agid";
import type { TenantConfiguration } from "../../models";
import { agid } from "../custom-types";
import { createBaseWithId, ownership } from "./base";
import { agConfig } from "./schema";

export const TenantStatus = pgEnum("tenant_status", [
  "CREATING",
  "ACTIVE",
  "INACTIVE",
  "SUSPENDED",
  "DELETED",
]);
export const TenantType = pgEnum("tenant_type", [
  "STANDARD",
  "TEST",
  "SANDBOX",
]);

// For tenants themselves, we don't want to give them a tenant ID.
const { tenantId: _, ...baseWithoutTenant } = createBaseWithId<TenantId>();

// tenantId is === for root org, or points to parent for others.
export const Tenant = agConfig.table("tenant", {
  ...baseWithoutTenant,
  ...ownership,

  shortId: varchar("short_id").unique().notNull(),
  name: varchar("name").unique().notNull(),
  description: varchar("description"),
  tenantType: TenantType("tenant_type").default("TEST").notNull(),
  tenantStatus: TenantStatus("tenant_status").default("CREATING").notNull(),
  parentId: agid("parent_id"),
  config: jsonb("config").$type<TenantConfiguration>(),
});
